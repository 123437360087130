import React from 'react';
import { Helmet } from 'react-helmet';
import { Heading, Text } from '../components/ui';
import Layout from '../components/Layout';

const CompanyNotFound = () => (
  <Layout>
    <Helmet title="404 Page Not Found | Impact Score" defer={false} />
    <Heading>404 Page Not Found</Heading>
    <Text>
      Sorry this page was not found and might not exist, please use the
      navigation at the bottom of the screen to get back on track.
    </Text>
  </Layout>
);

export default CompanyNotFound;
